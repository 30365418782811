import React from 'react';
import { T } from '@deity-io/falcon-i18n';
import clsx from 'clsx';
import './FormInput.scss';

const FormInput = props => {
  const {
    register,
    errors,
    type,
    text,
    name,
    refProp,
    className,
    defaultValue,
    isAdditionalInfo,
    isCheckbox,
    onChange,
    value,
    onClick,
    children,
    required = true,
    minLength,
    noAutoComplete,
  } = props;

  return (
    <div className={clsx('input-field', className, isCheckbox && 'input-field__checkbox')}>
      {text && (
        <label htmlFor={name} className={clsx(!isAdditionalInfo && 'input-field__label')}>
          {text}
        </label>
      )}
      {!children && (
        <input
          type={type}
          name={name}
          id={name}
          defaultValue={defaultValue}
          value={value}
          ref={refProp || register({ required })}
          className="input-field__input"
          onChange={onChange}
          autoComplete={noAutoComplete && 'new-password'}
          onClick={onClick}
          {...(minLength && { minLength })}
        />
      )}
      {children && (
        <select
          ref={refProp || register({ required: true })}
          errors={errors}
          name={name}
          id={name}
          defaultValue={defaultValue}
          onChange={onChange}
        >
          {children}
        </select>
      )}
      {errors[name] && (
        <span className="input-field__error">
          {errors[name].type === 'required' && <T id="formInput.required" />}
          {errors[name].type === 'oib' && <T id="formInput.oib" />}
          {errors[name].type === 'validate' && errors[name].message}
          {errors[name].type === 'minLength' && errors[name].message}
        </span>
      )}
    </div>
  );
};

export default FormInput;
