import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { getToken } from '../repositories/token';

function useApi(query, options) {
    const client = useApolloClient();

    const fetchQuery = async requestOptions => {
        return await client.query({
            query,
            ...options,
            ...requestOptions,
            // context: {
            //     headers: {
            //         Authorization: getToken() ? `Bearer ${getToken()}` : '',
            //     },
            // },
        });
    };

    const fetchMutation = async variables => {
        return await client.mutate({
            mutation: query,
            ...variables,
            ...options,
            context: {
                headers: {
                    Authorization: getToken() ? `Bearer ${getToken()}` : '',
                },
            },
        });
    };

    return { fetchQuery, fetchMutation };
}

export default useApi;
