import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { T } from '@deity-io/falcon-i18n';
import Button from '../../UI/Button/Button';
import SocialAuth from '../../UI/SocialAuth/SocialAuth';
import './UserNotAuth.scss';

const UserNotAuth = props => {
  return (
    <>
      <div className="account__wrapper">
        <div className="log-in">
          <span className="account__header">
            <T id="user.existingUser" />
          </span>
          <Link className="log-in__link" to="/customer/login/" onClick={props.mobilePopup}>
            <Button trait="hover" text={<T id="registration.accountLogin" />} />
          </Link>

          <SocialAuth isHeaderPopup />
        </div>
      </div>

      <div className="account__wrapper">
        <div className="create-account">
          <span className="account__header">
            <T id="user.newUser" />
          </span>
          <Link className="create-account__link" to="/customer/registration/" onClick={props.mobilePopup}>
            <Button trait="hover" text={<T id="registration.registerButton" />} />
          </Link>

          <ul className="create-account__bonuses">
            <li className="create-account__bonus">
              <T id="user.accountBonus1" />
            </li>
            <li className="create-account__bonus">
              <T id="user.accountBonus2" />
            </li>
            <li className="create-account__bonus">
              <T id="user.accountBonus3" />
            </li>
          </ul>
        </div>
      </div>

      <div className="account__wishlist">
        <img className="account__wishlist-icon " src="/images/emmezeta-whislist-icon.svg" alt="" />
        <span className="account__wishlist-text">
          <T id="user.wishlist" />
        </span>
      </div>
    </>
  );
};

export default memo(UserNotAuth);
