import React from 'react';
import SocialLogin from 'react-social-login';

class SocialButton extends React.Component {
    render() {
        const { triggerLogin, children, ...rest } = this.props;
        return (
            <div onClick={triggerLogin} {...rest}>
                {children}
            </div>
        );
    }
}

export default SocialLogin(SocialButton);
