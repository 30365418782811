import gql from 'graphql-tag';

export const GET_USER_DATA = gql`
    query {
        customer {
            firstname
            is_admin
            is_subscribed
            lastname
            suffix
            email
            id
            addresses {
                id
                firstname
                lastname
                street
                city
                country_id
                postcode
                telephone
                company
                vat_id
                default_billing
                default_shipping
            }
        }
    }
`;

export const REVOKE_TOKEN = gql`
    mutation {
        revokeCustomerToken {
            result
        }
    }
`;

export const GET_CARD = gql`
    {
        historyCustomerLoyaltyCard {
            message
            success
            loyalty {
                memberID
                cardID
                saldoPoints
                isActive
                store
                transID
                transDate
                description
                income
                expense
            }
        }
    }
`;

export const CITY_BY_POSTCODE = gql`
    query citiesByPostcode($city: String, $postCode: Int, $pageSize: Int = 50) {
        citiesByPostcode(city: $city, postCode: $postCode, pageSize: $pageSize) {
            city
            postcode
        }
    }
`;

export const IS_CUSTOMER_SUBSCRIBED = gql`
    query isSubscribed {
        isSubscribed {
            is_subscribed
        }
    }
`;

export const UPDATE_CUSTOMER = gql`
    mutation updateCustomerV2($input: CustomerUpdateInput!) {
        updateCustomerV2(input: $input ) {
            customer {
                firstname
                lastname
                is_subscribed
            }
        }
    }
`;
