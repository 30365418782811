import {useMutation, useQuery} from '@apollo/react-hooks';
import React from 'react';
import {T} from '@deity-io/falcon-i18n';
import {useHistory} from 'react-router-dom';
import {Loader} from '@deity-io/falcon-ecommerce-uikit';
import useApi from '../../../hooks/useApi';
import {EMAIL_AVAILABLE, GENERATE_TOKEN} from '../../../queries/auth';
import {CREATE_CUSTOMER, GET_CUSTOMER} from '../../../queries/customer';
import {GET_BACKEND_CONFIG} from '../../../commonQueries';
import {GET_CART} from '../../../queries/cart';
import Button from '../Button/Button';
import SocialButton from './SocialButton/SocialButton';
import './SocialAuth.scss';
import {useGoogleLogin} from '@react-oauth/google';
import axios from 'axios';

const SocialAuth = ({isHeaderPopup, isCheckout}) => {
    const history = useHistory();
    const {data: backendData, loading} = useQuery(GET_BACKEND_CONFIG);
    const [generateToken, {loading: loadingUser}] = useMutation(GENERATE_TOKEN, {
        refetchQueries: [{query: GET_CUSTOMER}, {query: GET_CART}],
        awaitRefetchQueries: true,
    });
    const [createCustomer] = useMutation(CREATE_CUSTOMER);
    const {fetchQuery: getEmailState} = useApi(EMAIL_AVAILABLE);

    const {facebook_client_id, google_client_id} = (!loading && backendData.configProvider) || {};

    const handleSocialLogin = async user => {
        try {
            const emailState = await getEmailState({variables: {email: user.email}});
            if (emailState.data.isEmailAvailable.is_email_available) {
                const {email, firstname, lastname} = user;
                await createCustomer({
                    variables: {
                        input: {
                            email,
                            firstname,
                            lastname,
                            oauth_token: user.authToken,
                            oauth_type: user.authType,
                        },
                    },
                });
            }
            await generateToken({
                variables: {
                    email: user.email,
                    authToken: user.authToken,
                    authType: user.authType,
                },
            });

            if (!isCheckout) {
                history.push(`${isHeaderPopup ? '/' : '/customer/account'}`);
            }
        } catch (error) {
            console.error(error);
        }
    };


    const googleLogin = useGoogleLogin({
        onSuccess: credentialResponse => {
            try {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentialResponse.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${credentialResponse.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        handleSocialLogin(
                            {
                                email: res.data.email,
                                firstname: res.data.given_name,
                                lastname: res.data.family_name,
                                authType: 'google',
                                authToken: credentialResponse.access_token,
                            }
                        )
                    })
            } catch (e) {
                console.log(e)
            }

        }
    });


    if (loadingUser) {
        return (
            <div className="social-auth__loader">
                <Loader/>
            </div>
        );
    }

    return (
        <>
            {facebook_client_id && (
                <SocialButton
                    provider="facebook"
                    appId={facebook_client_id}
                    onLoginSuccess={credentialResponse => {
                        handleSocialLogin({
                            email: credentialResponse._profile.email,
                            firstName: credentialResponse._profile.firstName,
                            lastName: credentialResponse._profile.lastName,
                            authType: 'facebook',
                            authToken: credentialResponse._token.accessToken,
                        })
                    }}
                    onLoginFailure={console.error}
                >
                    <Button type="button" trait="facebook" text={<T id="registration.facebook"/>}/>
                </SocialButton>
            )}

            {google_client_id && (
                <button type="button" className="btn btn_google"
                        onClick={() => googleLogin()}
                >
                    <div className="btn__info">
                        <img src="/images/goog-icon.png" className="btn__logo" alt="google_logo"/>
                        <span className="btn__text">Google prijava</span>
                    </div>
                </button>
            )}

        </>
  );
};

export default SocialAuth;
