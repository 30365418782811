import { useMutation, useQuery } from '@apollo/react-hooks';
import { T, I18nContext } from "@deity-io/falcon-i18n";
import React, { useContext, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/UI/Button/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import FormInput from '../../components/UI/FormInput/FormInput';
import SocialAuth from '../../components/UI/SocialAuth/SocialAuth';
import { GENERATE_TOKEN } from '../../queries/auth';
import { CREATE_CUSTOMER, GET_CUSTOMER } from '../../queries/customer';
import { GET_BACKEND_CONFIG } from '../../commonQueries';
import { usePopup } from "../../context/PopupContext";
import { Loader } from '@deity-io/falcon-ecommerce-uikit';
import './Registration.scss';
import { GET_CART } from '../../queries/cart';
import {UPDATE_CUSTOMER} from "../PersonalAreaScreen/queriesPersonalArea";

const Registration = () => {
  const recaptchaRef = useRef();
  const { register, handleSubmit, errors, getValues, setValue } = useForm();
  const history = useHistory();
  const location = useLocation();
  const { t } = useContext(I18nContext);
  const { showAddToCartPopupMessage } = usePopup();

  const { data } = useQuery(GET_BACKEND_CONFIG);
  const [createCustomerV2, { error: mutationError, loading }] = useMutation(CREATE_CUSTOMER);
  const [updateCustomerV2] = useMutation(UPDATE_CUSTOMER);
  const [generateToken, { loading: loggingIn }] = useMutation(GENERATE_TOKEN, {
    refetchQueries: [{ query: GET_CUSTOMER }, { query: GET_CART }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if(mutationError){
      const errorMessage = mutationError.message.includes('email')
        ? t('registration.sameEmail')
        : mutationError.message;
      showAddToCartPopupMessage(errorMessage, 'error', 4000);
      setValue('email', '');
    }
  }, [mutationError]);

  const onSubmit = async formData => {
    const recaptchaToken = await recaptchaRef.current.executeAsync().catch(console.error);
    recaptchaRef.current.reset();
    const { email, firstname, lastname, password, is_subscribed } = formData;
    await createCustomerV2({
      variables: {
        input: {
          email,
          firstname,
          lastname,
          password,
          is_subscribed
        },
      },
      context: {
        headers: {
          'X-ReCaptcha': recaptchaToken,
        },
      },
    });
    await generateToken({
      variables: { email, password },
    });

    if (location.state && location.state.redirectToCheckout) {
      history.push('/checkout');
    } else {
      history.push('/customer/account');
    }
  };

  return (
    <div className="registration-wrapper">
      <div className="registration">
        <legend className="registration__legend">
          <T id='registration.header' />
        </legend>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <ReCAPTCHA sitekey={data && data.configProvider.captchaWebsiteKey} ref={recaptchaRef} size="invisible" />
          <div className="form__section">
            <legend className="form__legend">
              <T id='registration.account' />
            </legend>
            <FormInput
              register={register}
              errors={errors}
              text={<T id="registration.email" />}
              type="text"
              name="email"
            />
            <FormInput
              errors={errors}
              text={<T id="registration.password" />}
              type="password"
              name="password"
              refProp={register({
                required: true,
                minLength: {
                  value: 8,
                  message: t('edit.passwordError'),
                },
              })}
            />
            <FormInput
              errors={errors}
              text={<T id="registration.passwordRepeat" />}
              type="password"
              name="repeatPassword"
              refProp={register({
                required: true,
                validate: value => value === getValues().password || t("edit.repeatPasswordsError"),
              })}
            />
            <div>{errors.registration && errors.registration.message}</div>
          </div>
          <div className="form__section">
            <legend className="form__legend">
              <T id='registration.personalInfo' />
            </legend>
            <FormInput register={register} errors={errors} text={<T id="registration.name" />} type="text" name="firstname" />
            <FormInput register={register} errors={errors} text={<T id="registration.lastName" />} type="text" name="lastname" />
            <div className="newsletter">
              <input
                ref={register}
                type="checkbox"
                className="newsletter__checkbox"
                name="is_subscribed"
              />
              <label htmlFor="is_subscribed" className="newsletter__label">
                <T id='registration.newsLetter' />
              </label>
            </div>
          </div>
          {loading || loggingIn ? (
            <Loader height="xxl" />
          ) : (
            <Button type="submit" text={<T id="registration.registerButton2" />} trait="hover-submit" />
          )}

          <SocialAuth />
        </form>
      </div>
    </div>
  );
};

export default React.memo(Registration);
