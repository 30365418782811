import React, { useContext } from 'react';
import { Loader } from '@deity-io/falcon-ecommerce-uikit';
import { I18nContext, T } from '@deity-io/falcon-i18n';
import { NetworkStatus } from 'apollo-client';
import { useForm } from 'react-hook-form';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { usePopup } from '../../context/PopupContext';
import Button from '../../components/UI/Button/Button';
import FormInput from '../../components/UI/FormInput/FormInput';
import SocialAuth from '../../components/UI/SocialAuth/SocialAuth';
import { useUserContext } from '../../context/UserContext';
import './Login.scss';

const Login = () => {
  const { register, handleSubmit, setError, errors } = useForm();
  const { user, loading, logIn, userStatus } = useUserContext();
  const location = useLocation();
  const { showAddToCartPopupMessage } = usePopup();
  const { t } = useContext(I18nContext);

  const userIsLoading = userStatus === NetworkStatus.loading;

  if (user) {
    const searchParams = new URLSearchParams(location.search);
    return <Redirect to={searchParams.get('referrer') || '/customer/account'} />;
  }

  const onSubmit = async ({ email, password }) => {
    try {
      await logIn(email, password).catch(() => showAddToCartPopupMessage(t('login.wrongPassword'), 'error', 4000));
      setTimeout(() => {
        if (navigator.userAgent.match(/Android|iPad|iPhone|iPod/i)) {
          window && window.ReactNativeWebView.postMessage(token.data.generateCustomerTokenLogin.token);
        }
      }, 0);
    } catch (e) {
      setError('auth', 'notMatch', `${e.graphQLErrors[0].message}`);
    }
  };

  return (
    <div className="customer">
      <div className="login-wrapper">
        <div className="login">
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <legend className="form__legend">
              <T id="login.existingCustomer" />
            </legend>
            <FormInput register={register} errors={errors} text={<T id="login.email" />} type="text" name="email" />
            <FormInput
              register={register}
              errors={errors}
              text={<T id="login.password" />}
              type="password"
              name="password"
            />
            <Link to="/customer/account/forgotpassword/" className="password-forgot">
              <T id="login.forgotPassword" />
            </Link>
            {/*<a className="log-in__link" href="/">*/}
              {!loading && <Button trait="hover-submit" text={<T id="login.login" />} />}
              {loading && <Loader height="xxxl" />}
            {/*</a>*/}
            {!userIsLoading && <SocialAuth />}
          </form>
        </div>
      </div>
      <div className="new-customer-wrapper">
        <div className="new-customer">
          <form className="form">
            <legend className="form__legend">
              <T id="login.newCustomer" />
            </legend>
            <div className="new-customer__text">
              <p>
                <T id="login.accountMessage" />
              </p>
            </div>
            <ul className="new-customer__benefits">
              <li className="new-customer__benefit">
                <T id="login.benefit1" />
              </li>
              <li className="new-customer__benefit">
                <T id="login.benefit2" />
              </li>
              <li className="new-customer__benefit">
                <T id="login.benefit3" />
              </li>
              <li className="new-customer__benefit">
                <T id="login.benefit4" />
              </li>
            </ul>
            <Link to="/customer/registration/" className="new-customer__create">
              <Button trait="hover-submit" text={<T id="login.createAccount" />} />
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Login);
